
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: ''
  }

  private tableData = {
    loading: false,
    data: []
  }

  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.trash.selectTrashcanPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      deviceNumber: this.info.deviceNumber
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  toTrashcanDetail (deviceId: string) {
    this.$router.push({ name: 'trashcanDetail', params: { deviceId: deviceId, projectId: this.projectId } })
  }
}
